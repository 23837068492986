import React from 'react';

import Layout from '../components/Layout/';
import BlogListing from '../components/BlogListing/';

export default class BlogPage extends React.Component {
    render () {
        return (
            <Layout title='Blog'>
                <article>

                    <header>
                        <h1>Blog</h1>
                    </header>

                    <BlogListing showYearSeparator={true} />

                </article>
            </Layout>
        );
    }
}
