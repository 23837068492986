import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';

import './styles.scss';

export const query = graphql`
    query BlogListingQuery {
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: {frontmatter: {type: {eq: "blog"}}}) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 160)
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        date(formatString: "dddd Do MMMM YYYY")
                    }
                }
                previous {
                    frontmatter {
                        date(formatString: "dddd Do MMMM YYYY")
                    }
                }
            }
        }
    }
`;

const getYear = (d) => {
    const format = 'dddd Do MMMM YYYY';
    return moment(d, format).year();
};

const isDifferentYear = (d1, d2) => {
    return getYear(d1) !== getYear(d2);
};

const YearSeparator = ({ date }) => <h2><CalendarIcon /> Posts From {getYear(date)}</h2>

const CalendarIcon = () => <FontAwesomeIcon icon={faCalendarAlt} style={{color: '#ff3055', 'margin': '0 .5em 0 -1.5em'}}/>;

const MaybeYearSeparator = ({ showYearSeparator, node, previous }) => (showYearSeparator || false) && (!previous || isDifferentYear(node.frontmatter.date, previous.frontmatter.date)) ? <YearSeparator date={node.frontmatter.date} /> : null

const PostEntry = ({ excerpt, fields, frontmatter }) => (
    <article className='blog-listing-entry'>
        <header>
            <h4><Link to={'/blog'+fields.slug}>{frontmatter.title}</Link></h4>
        </header>
        <section>
            <p>{excerpt}</p>
        </section>
        <footer className='blog-listing-metadata'>
            <p>Posted on {frontmatter.date} &mdash; <Link to={'/blog'+fields.slug}>Continue Reading →</Link></p>
        </footer>
    </article>
);

export default class BlogListing extends React.Component {

    render () {
        const { showYearSeparator } = this.props;
        return (
            <StaticQuery query={query} render={(props) => props.allMarkdownRemark.edges.map(({ node, previous }) => (
                <>
                    <MaybeYearSeparator showYearSeparator={showYearSeparator} node={node} previous={previous} />
                    <PostEntry key={node.id} {...node} />
                </>
            ))} />
        );
    }

}

